import { combineReducers } from 'redux'
import login from './login'
import reviews from './reviews'
import support from './support'
import contact from './contact'
import menus from './menus'
import users from './users'
import admins from './admins'
import mishloha from './mishloha'

export default combineReducers({
	login, reviews, support, contact, menus, users, admins, mishloha
});