import React, {Component} from 'react';
import Text from "../utils/Text";
import {Link} from 'react-router-dom';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import LineInput from "../utils/LineInput";

const MAX_ITEMS_TO_SHOW = 30;

export default class RestaurantList extends Component {

	searchDebounceTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			search : '',
		}
	}

	filteredList() {
		const {search} = this.state;
		const list = (search && search.length > 0) ? this.props.list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) : this.props.list;
		// return list;
		return (list) ? list.slice(0, MAX_ITEMS_TO_SHOW) : [];
	}

	onSearchChange = (search) => {
		this.setState({search});

		clearTimeout(this.searchDebounceTimeout);
		this.searchDebounceTimeout = setTimeout(() => this.searchMenuSections(), 300);

	};

	searchMenuSections() {
	}

	render() {

		const list = this.filteredList();

		return (
			<div className={'card border-secondary'}>
				<div className="card-header">
					<h4><Text>menus.restaurants</Text></h4>
				</div>
				<div className="card-header">
					<LineInput noButtons={1} icon="search" value={this.state.search} onSubmit={this.onSearchChange} />
				</div>
				<ul className="list-group list-group-flush" style={{height: 'calc(100vh - 350px)', overflowY: 'auto'}}>
				{list.length ? list.map((restaurant) => (
					<li key={restaurant.id} className="list-group-item">
						<Row>
							<Column className="ibs">
								<span>{restaurant.name}</span>
								<span>[{restaurant.num}]</span>
							</Column>
							<Column autoCol={1}>
								<Link to={'/menus/'+restaurant.id}><Button primary={1}><Icon>edit</Icon></Button></Link>
							</Column>
						</Row>
					</li>
				)):''}
				</ul>
			</div>
		);
	}
}