import {Api} from "../resources/Api";

export const USERS_GET_COUNT_SUCCESS = 'USERS_GET_COUNT_SUCCESS';
export const USERS_GET_COUNT_ERROR = 'USERS_GET_COUNT_ERROR';
export const USERS_GET_COUNT_DAILY_SUCCESS = 'USERS_GET_COUNT_DAILY_SUCCESS';
export const USERS_GET_COUNT_DAILY_ERROR = 'USERS_GET_COUNT_DAILY_ERROR';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_ERROR = 'USERS_GET_LIST_ERROR';
export const USERS_SIGN_OUT_SUBSCRIBER_SUCCESS = 'USERS_SIGN_OUT_SUBSCRIBER_SUCCESS';
export const USERS_SIGN_OUT_SUBSCRIBER_ERROR = 'USERS_SIGN_OUT_SUBSCRIBER_ERROR';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_ERROR = 'USERS_DELETE_ERROR';
export const LOADING = 'LOADING_USERS';


export const getUserCount = () => {
	return async (dispatch) => {

		dispatch({
			type: LOADING
		});

		const res = await Api.usersGetCount();
		if (res.anonymous) {
			return dispatch({ type: USERS_GET_COUNT_SUCCESS, anonymous: res.anonymous, regular: res.regular, subscriber: res.subscriber });
		}
		else return dispatch({ type: USERS_GET_COUNT_ERROR, error: res.error });
	}
};

export const getUserCountDaily = () => {
	return async (dispatch) => {

		dispatch({
			type: LOADING
		});

		const res = await Api.usersGetCountDaily();
		if (res.list) {
			return dispatch({ type: USERS_GET_COUNT_DAILY_SUCCESS, list: res.list });
		}
		else return dispatch({ type: USERS_GET_COUNT_DAILY_ERROR, error: res.error });
	}
};

export const getUsers = () => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.usersGetList();
		if (res.result === "success") {
			return dispatch({ type: USERS_GET_LIST_SUCCESS, list: res.list });
		}
		else return dispatch({ type: USERS_GET_LIST_ERROR, error: res.error });
	}
};

export const signOutSubscriber = (userId) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.usersSignOutSubscriber(userId);
		if (res.result === "success") {
			return dispatch({ type: USERS_SIGN_OUT_SUBSCRIBER_SUCCESS, userId });
		}
		else return dispatch({ type: USERS_SIGN_OUT_SUBSCRIBER_ERROR, error: res.error });
	}
};

export const deleteUser = (userId) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.usersDeleteUser(userId);
		if (res.result === "success") {
			return dispatch({ type: USERS_DELETE_SUCCESS, userId });
		}
		else return dispatch({ type: USERS_DELETE_ERROR, error: res.error });
	}
};