import React, {PureComponent} from 'react';
import { withRouter } from 'react-router-dom'

class Button extends PureComponent {

	render() {
			
		let btnClass = 'btn-default';
		if (this.props.primary) btnClass = 'btn-primary';
		if (this.props.secondary) btnClass = 'btn-secondary';
		if (this.props.success) btnClass = 'btn-success';
		if (this.props.light) btnClass = 'btn-light';
		if (this.props.dark) btnClass = 'btn-dark';
		if (this.props.info) btnClass = 'btn-info';
		if (this.props.warning) btnClass = 'btn-warning';
		if (this.props.danger) btnClass = 'btn-danger';

		if (this.props.outline) btnClass = btnClass.replace('btn','btn-outline');

		if (this.props.disabled) btnClass += ' disabled';
		if (this.props.block) btnClass += ' btn-block';
		if (this.props.small) btnClass += ' btn-sm';
		else if (this.props.xs) btnClass += ' btn-xs';
		if (this.props.spaced) btnClass += ' mx-'+this.props.spaced;

		let onClick = (this.props.onClick)?this.props.onClick:()=>null;
		if (this.props.to) {
			onClick = () => this.props.history.push(this.props.to);
		}

		let submit = this.props.type === "submit";

		return (submit?
			<button type="submit" onClick={onClick} className={'btn' + (btnClass?' '+btnClass:'') + (this.props.className?' '+this.props.className:'')}>{this.props.children}</button>
			:
			<div onClick={onClick} className={'btn' + (btnClass?' '+btnClass:'') + (this.props.className?' '+this.props.className:'')}>{this.props.children}</div>)
	}
};

export default withRouter(Button);