import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LoginBox from "../components/login/LoginBox";
import * as loginActions from '../actions/loginActions';
import * as menusActions from '../actions/menusActions';
import Text from "../components/utils/Text";
import Button from "../components/utils/Button";
import Icon from "../components/utils/Icon";
import Confirmation from "../components/utils/Confirmation";
import {TEST_ENV} from "../resources/Api";

class Intro extends Component {

	constructor() {
		super();
		this.state = {
			reloading : false,
		}
	}

	toggleReloading = () => this.setState({reloading : !this.state.reloading});



	onReloadRestaurants = () => {
		const {reloadRestaurants} = this.props.menusActions;
		reloadRestaurants();
	};

	render() {

		const {loginActions, login, reviews, support, contact, menus, users} = this.props;
		const supportTickets = (support.tickets) ? support.tickets.length : 0;
		const unreadSupportTickets = (support.tickets) ? support.tickets.filter(ticket => !ticket.read).length : 0;
		const openSupportTickets = (support.tickets) ? support.tickets.filter(ticket => !ticket.solved).length : 0;
		const contactTickets = (contact.tickets) ? contact.tickets.length : 0;
		const unreadContactTickets = (contact.tickets) ? contact.tickets.filter(ticket => !ticket.read).length : 0;
		const openContactTickets = (contact.tickets) ? contact.tickets.filter(ticket => !ticket.solved).length : 0;

		const reviewsIconCls = reviews.pendingReviews.length >= 50 ? 'text-danger' : reviews.pendingReviews.length >= 10 ? 'text-warning' : 'text-secondary';
		const reviewsTextCls = reviews.pendingReviews.length >= 50 ? 'text-danger font-weight-bold' :  reviews.pendingReviews.length >= 10 ? 'text-danger' : 'text-secondary';

		const menuItems = menus.restaurants.length > 0 ? Object.keys(menus.restaurants).reduce((acc, val) => acc*1 + menus.restaurants[val].num*1) : 0;
		const userCount = users.count;

		const {reloading} = this.state;
		return (
			<div>
				{!login.loading && <LoginBox doLogin={loginActions.login} credentials={login.credentials} loggedIn={login.loggedIn} error={login.loginError} />}

				{login.loggedIn &&
				<div>
					<div className={'alert ' + (TEST_ENV?'alert-danger':'alert-info')}>
						<b><Text>{!TEST_ENV?'greetings.welcome':'greetings.testEnvironment'}</Text></b> <Text>{!TEST_ENV?'greetings.welcomeText':'greetings.testEnvironmentText'}</Text>
					</div>
					<div className="card-deck mt-3 bg-light rounded px-2 py-4">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-secondary"><Text>sections.reviews</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									{reviews.pendingReviews.length>0 && <Icon className={'mx-2 '+reviewsIconCls}>exclamation-triangle</Icon>}
								<span className={reviewsTextCls}><Text num={reviews.pendingReviews.length}>reviews.numPendingReviews</Text></span>
								</li>
							</ul>
							<div className="card-footer">
								<Button to="/reviews" secondary={1} block={1}><Text>admin.manageReviews</Text></Button>
							</div>
						</div>
						<div className={"card"}>
							<div className="card-body">
								<h5 className="card-title text-danger"><Text>sections.support</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									<span className={(unreadSupportTickets > 0?'text-danger font-weight-bold':'text-info')}>
										<Icon className="mx-2">envelope</Icon>
										<Text num={unreadSupportTickets}>support.numUnreadTickets</Text>
									</span>
								</li>
								<li className={"list-group-item "+(openSupportTickets > 0?'text-primary':'text-info')}>
									<span className={(openSupportTickets > 0?'text-warning':'text-info')}>
										<Icon className={'mx-2'}>envelope-open-text</Icon>
									</span>
									<Text num={openSupportTickets}>support.numOpenTickets</Text>
								</li>
								<li className="list-group-item"><Icon className="text-secondary mx-2">clipboard-list</Icon><Text num={supportTickets}>support.numTotalTickets</Text></li>
							</ul>
							<div className="card-footer">
								<Button to="/support" block={1} className="btn-danger"><Text>admin.manageSupport</Text></Button>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-info"><Text>sections.contact</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									<span className={(unreadContactTickets > 0?'text-danger font-weight-bold':'text-info')}>
										<Icon className="mx-2">envelope</Icon>
										<Text num={unreadContactTickets}>contact.numUnreadMessages</Text>
									</span>
								</li>
								<li className={"list-group-item "+(openContactTickets > 0?'text-primary':'text-info')}>
									<span className={(openContactTickets > 0?'text-warning':'text-info')}>
										<Icon className={'mx-2'}>envelope-open-text</Icon>
									</span>
									<Text num={openContactTickets}>contact.numUnsolvedMessages</Text>
								</li>
								<li className="list-group-item"><Icon className="text-secondary mx-2">clipboard-list</Icon><Text num={contactTickets}>contact.numTotalTickets</Text></li>
							</ul>
							<div className="card-footer">
								<Button to="/contact" info={1} block={1}><Text>admin.manageContact</Text></Button>
							</div>
						</div>
					</div>

					<div className="card-deck mt-3 rounded px-2 py-0">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-primary"><Text>sections.menus</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item"><Icon className="text-secondary mx-2">utensils</Icon><Text num={menus.restaurants.length}>menus.numRestaurants</Text></li>
								<li className="list-group-item"><Icon className="text-secondary mx-2">list</Icon><Text num={menus.menuSections.length}>menus.numMenuSections</Text></li>
								<li className="list-group-item"><Icon className="text-secondary mx-2">pizza-slice</Icon><Text num={menuItems}>menus.numMenuItems</Text></li>
							</ul>
							<div className="card-footer">
								<Button to="/menus" primary={1} block={1}><Text>admin.manageMenus</Text></Button>
							</div>
						</div>

						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-primary"><Text>sections.users</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								<li className="list-group-item"><Icon className="text-primary mx-2">frown</Icon><Text num={userCount.anonymous}>users.numAnonymousUsers</Text></li>
								<li className="list-group-item"><Icon className="text-info mx-2">meh</Icon><Text num={userCount.regular}>users.numRegularUsers</Text></li>
								<li className="list-group-item"><Icon className="text-success mx-2">smile</Icon><Text num={userCount.subscriber}>users.numSubscribers</Text></li>
							</ul>
							<div className="card-footer">
								<Button to="/users" primary={1} block={1}><Text>admin.manageUsers</Text></Button>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-primary"><Text>admin.actions</Text></h5>
							</div>
							<ul className="list-group list-group-flush">
								{login.superAdmin && <li className="list-group-item">
									<Button dark={1} block={1} to="/admins"><Icon className="text-warning mx-2">star</Icon><Text>admins.manageAdmins</Text></Button>
								</li>}
								<li className="list-group-item">
									<Button success={1} block={1} to="/mishloha"><Text>admin.mishloha</Text></Button>
								</li>
								<li className="list-group-item">
									{reloading ?
										<Confirmation onDismiss={this.toggleReloading} onConfirm={this.onReloadRestaurants}/>:
										<Button primary={1} block={1} onClick={this.toggleReloading}><Text>admin.clearRestaurantCache</Text></Button>
									}
								</li>
							</ul>
						</div>
					</div>
				</div>}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, reviews: state.reviews, support: state.support, contact: state.contact, menus : state.menus, users: state.users }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		menusActions: bindActionCreators({...menusActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Intro);