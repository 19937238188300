import React from 'react';
import t from '../resources/i18n';

export function DateFormat(date) {
	const r = new Date(Date.parse(date));
	let yr = r.getFullYear(), mo = r.getMonth()+1, dy = r.getDate();
	return dy+'/'+mo+'/'+yr;
}

export function DateTimeFormat(date) {

	const r = new Date(Date.parse(date));
	let yr = r.getFullYear(), mo = r.getMonth()+1, dy = r.getDate(),
		hr = r.getHours()+'', min = r.getMinutes()+'';

	hr = (hr.length > 1)?hr:'0'+hr;
	min = (min.length > 1)?min:'0'+min;

	return dy+'/'+mo+'/'+yr+' '+hr+':'+min;
}
export function Ellipsis(text, threshold = 30) {
	return text?text.substr(0, (text.length > threshold)?threshold-3:threshold) + ((text.length > threshold)?'...':''):'';
}

export function Nl2br(text) {
	return text.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})
}

export function UserStatus(status) {
	switch (status) {
		case 'anonymous': return t.t("users.anonymous");
		case 'regular': return t.t("users.regular");
		case 'subscriber': return t.t("users.subscriber");
		default: return '';
	}
}

export function userSearch(search, userList) {
	return userList.map(user => {
		const matches = [];
		if (user.email && user.email.toLowerCase().indexOf(search.toLowerCase()) > -1) matches.push("email");
		if (user.first_name && user.first_name.toLowerCase().indexOf(search.toLowerCase()) > -1) matches.push("first_name");
		if (user.last_name && user.last_name.toLowerCase().indexOf(search.toLowerCase()) > -1) matches.push("last_name");

		if (user.id && (user.id+'').indexOf(search+'') > -1) matches.push("id");
		if (user.device_id && user.device_id.toLowerCase().indexOf(search.toLowerCase()) > -1) matches.push("device_id");
		if (user.device_type && user.device_type.toLowerCase() === search.toLowerCase()) matches.push("device_type");
		if (user.version && user.version.toLowerCase() === search.toLowerCase()) matches.push("version");

		if (matches.indexOf('first_name') < 0 && matches.indexOf('last_name') < 0) {
			if (user.first_name && user.last_name && (user.first_name.toLowerCase() + ' ' +user.last_name.toLowerCase()).indexOf(search.toLowerCase()) > -1) matches.push("full_name");
		}

		user.searchMatch = (matches.length > 0) ? matches : false;
		return user;
	})
		.filter(user => !!user.searchMatch);
}