import React, {Component} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import Confirmation from "../utils/Confirmation";
import Text from "../utils/Text";
import {DateTimeFormat, UserStatus} from "../../resources/Filters";

export default class UserRow extends Component {

	constructor() {
		super();
		this.state = {
			deleting : false,
		}
	}

	toggleDelete = () => {
		this.setState({deleting : !this.state.deleting });
	};

	onDeleteUser = () => {
		this.props.onDeleteUser();
	};

	onSignOutSubscriber = () => {
		this.props.onSignOutSubscriber();
	};

	render() {

		const {user} = this.props;
		const {deleting} = this.state;

		return (

			<Row centered={1}>
				<Column className="col-md-auto small">
					<div className={(user.searchMatch && user.searchMatch.indexOf("id")>-1?' text-primary font-weight-bold':'')}>{user.id}</div>
				</Column>
				<Column className="col-md"><span className={(user.searchMatch && (user.searchMatch.indexOf("first_name")>-1 || user.searchMatch.indexOf("full_name")>-1)?' text-primary font-weight-bold':'text-secondary')}>{user.first_name}</span> <span className={(user.searchMatch && (user.searchMatch.indexOf("last_name")>-1 || user.searchMatch.indexOf("full_name")>-1)?' text-primary font-weight-bold':'')}>{user.last_name}</span></Column>
				<Column className="col-md">
					<div>
						<Icon className={'mr-1 '+(user.status==="subscriber"?'text-success':user.status==="regular"?'text-info':'text-primary')}>
							{user.status==="subscriber"?'smile':user.status==="regular"?'meh':'frown'}
						</Icon>
						{UserStatus(user.status)}
					</div>
					<div className="small">
						<div className="text-info"><Text date={DateTimeFormat(user.created)}>users.dateCreated</Text></div>
						{user.last_login && <div className="text-primary"><Text date={DateTimeFormat(user.last_login)}>users.lastLogin</Text></div>}
					</div>
				</Column>
				<Column className="col-md text-right small">
					<div className={(user.searchMatch && user.searchMatch.indexOf("email")>-1?' text-primary font-weight-bold':'text-secondary')}>{user.email}</div>
					<code className={"small "+(user.searchMatch && user.searchMatch.indexOf("device_id")>-1?' text-primary font-weight-bold':'text-secondary')}>{user.device_id}</code>
				</Column>
				<Column className="col-md-1 text-right small">
					<code className={"small "+(user.searchMatch && user.searchMatch.indexOf("device_type")>-1?' text-primary font-weight-bold':'text-secondary')}>{user.device_type}</code>
					<div className={(user.searchMatch && user.searchMatch.indexOf("version")>-1?' text-primary font-weight-bold':'text-secondary')}>{user.version}</div>
				</Column>
				<Column autoCol={1}>
					{deleting?
						<Confirmation onDismiss={this.toggleDelete} onConfirm={this.onDeleteUser} />
						:
						<div>
							<Button primary={user.status !== "subscriber"?0:1} disabled={user.status !== "subscriber"?1:0}
									onClick={user.status === "subscriber"?() => this.onSignOutSubscriber(user.id):null}>
								<Text>users.signOutSubscriber</Text>
							</Button>
							<Button danger={1} spaced={1} onClick={this.toggleDelete}>
								<Text>users.deleteSubscriber</Text>
							</Button>
						</div>
					}
				</Column>
			</Row>
		);
	}
}