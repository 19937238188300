import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as usersActions from '../actions/usersActions';
import Text from "../components/utils/Text";
import LineInput from "../components/utils/LineInput";
import UserRow from "../components/users/UserRow";
import {userSearch} from "../resources/Filters";
import Button from "../components/utils/Button";
import Icon from "../components/utils/Icon";
import {Row, Column} from "../components/utils/Row";

const MAX_USERS_DEFAULT = 50;
const MAX_USERS_SEARCH = 150;

class Users extends Component {

	constructor() {
		super();

		this.state = {
			search : '',
		}
	}

	onChangeSearch = (search) => {
		this.setState({
			search
		})
	};

	componentDidMount() {

		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		const {userList} = this.props.users;
		const {getUsers} = this.props.usersActions;

		if (userList === null) {
			getUsers();
		}
	}

	onSignOutSubscriber = (id) => {
		const {signOutSubscriber} = this.props.usersActions;
		signOutSubscriber(id);
	};

	onDeleteUser = (id) => {
		const {deleteUser} = this.props.usersActions;
		deleteUser(id);
	};

	render() {

		let {userList} = this.props.users;
		const {search} = this.state;
		const total = (userList ? userList.length : 0);

		if (userList) {
			if (search) {
				userList = userSearch(search, userList);
				userList = userList.slice(0, MAX_USERS_SEARCH);
			}

			else {
				userList = userList.slice(0, MAX_USERS_DEFAULT);
			}
		}

		const num = (userList ? userList.length : 0);

		return (
			<div className="card">
				<div className="card-body">
					<Row>
						<div className="card-title text-primary">
							<h5><Text>users.users</Text></h5>
							<p><Text total={total} num={num}>users.showing</Text></p>
						</div>
						<Column autoCol={1}>
							<Button info={1} to={'/users-data'}><Icon>chart-line</Icon></Button>
						</Column>
					</Row>
				</div>
				<div className="card-header">
					<LineInput noButtons={1} icon="search" onSubmit={this.onChangeSearch} />
				</div>

				{userList &&
				<div className="list-group list-group-flush" style={{height: 'calc(100vh - 250px)', overflowY: 'auto'}}>
					{userList.map(user =>
					<div key={'user'+user.id} className="list-group-item">
						<UserRow user={user}
							 onSignOutSubscriber={() => this.onSignOutSubscriber(user.id)}
								 onDeleteUser={() => this.onDeleteUser(user.id)}
						/>
					</div>)}
				</div>}

			</div>
		)
	}
}

const mapStateToProps = state => {
	return {users: state.users, login: state.login }
};

const mapDispatchToProps = dispatch => {
	return { usersActions: bindActionCreators({...usersActions}, dispatch) }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);