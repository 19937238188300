import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import './style.scss';
import Intro from "./containers/Intro";
import Menus from "./containers/Menus";
import Reviews from "./containers/Reviews";
import Navbar from "./components/navbar/Navbar";
import Support from "./containers/Support";
import Contact from "./containers/Contact";
import Users from "./containers/Users";
import UserGraphs from "./containers/UserGraphs";
import Admins from "./containers/Admins";
import Mishloha from "./containers/Mishloha";
import Logout from "./containers/Logout";

import {LOGIN_RESTORE_HASH} from "./actions/loginActions";
import {Api, TEST_ENV} from "./resources/Api";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


// UNCOMMENT for old env.
// const testEnvPath = '/app/webroot/appdash2';
// const prodEnvPath = '/app/webroot/subtlyHiddenAppDashboard';
// const isProductionBuild = (process.env.NODE_ENV === "production");
// const baseName = isProductionBuild ? (TEST_ENV ? testEnvPath : prodEnvPath) : '';

const baseName = '';

const adminHash = localStorage.getItem('vf-admin-hash');
const adminUsername = localStorage.getItem('vf-admin-username');
const isSuperAdmin = localStorage.getItem('vf-is-super-admin');
if (adminHash) {
	Api._credentials.hash = adminHash;
	store.dispatch({
		type: LOGIN_RESTORE_HASH,
		hash: adminHash,
		username : adminUsername,
		superAdmin: !!isSuperAdmin
	});
}

function App() {

  return (
      <Provider store={store}>
          <Router basename={baseName}>
        	<div className="App he">
			  <Navbar />
				<div className="container">
					<Route path={'/'} exact component={Intro} />
              		<Route path={'/menus'} exact component={Menus} />
              		<Route path={'/menus/:id'} component={Menus} />
              		<Route path={'/reviews'} exact component={Reviews} />
					<Route path={'/support'} exact component={Support} />
					<Route path={'/support/:id'} component={Support} />
					<Route path={'/contact'} exact component={Contact} />
					<Route path={'/contact/:id'} component={Contact} />
					<Route path={'/users'} exact component={Users} />
					<Route path={'/users-data'} exact component={UserGraphs} />
              		<Route path={'/admins'} exact component={Admins} />
              		<Route path={'/mishloha'} exact component={Mishloha} />
              		<Route path={'/logout'} component={Logout} />
				</div>
        	</div>
          </Router>
      </Provider>
  );
}

export default App;
