import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as menusActions from '../actions/menusActions';
import Text, {Xlate} from "../components/utils/Text";
import RestaurantList from "../components/menus/RestaurantList";
import MenuSectionsList from "../components/menus/MenuSectionsList";
import RestaurantMenuItems from "../components/menus/RestaurantMenuItems";
import {Column, Row} from "../components/utils/Row";
import Select from "../components/utils/Select";

class Menus extends Component {

	constructor() {
		super();

		this.state = {
			openRestaurant : null,
			data : null,
		}
	}

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		this.checkOpenRestaurant(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.checkOpenRestaurant(nextProps);
	}

	checkOpenRestaurant(props) {
		if (
			(!this.state.openRestaurant || (props.match.params && props.match.params['id']*1 !== this.state.openRestaurant*1))
			&& !props.menus.menuItemsCleared && props.menus.restaurants.length > 0) {

			this.setOpenRestaurant(props);
		}
	}

	setOpenRestaurant(props) {
		const {restaurants} = props.menus;

		if (props.match.params && props.match.params['id']) {
			const id = props.match.params['id']*1;
			this.setState({openRestaurant : id});

			let data = null;
			for (let rest of restaurants) { if (rest.id*1 === id) { data = rest; break; }}

			if (data) {
				this.setState({ data });
				this.props.menusActions.clearMenuItems();
				this.props.menusActions.menuItemsList(id);
			}
		}
	};

	onChangeRestaurant = (restaurantId) => {
		this.props.history.push('/menus/'+restaurantId);
	};

	onMergeSection = (oldSection, newSection) => {
		this.props.menusActions.menuSectionsMerge(oldSection, newSection);
	};

	onEditSection = (id, title) => {
		this.props.menusActions.editMenuSection(id, title);
	};

	onAddSection = (title, position) => {
		this.props.menusActions.addMenuSection(title, position);
	};

	onAddRestaurantSection = (sectionId) => {
		this.props.menusActions.addRestaurantMenuSection(sectionId);
	};

	onAddItem = (restaurantId, sectionId, title, description, price) => {
		this.props.menusActions.addMenuItem(restaurantId, sectionId, title, description, price);
	};

	onEditItem = (id, title, description, price) => {
		this.props.menusActions.editMenuItem(id, title, description, price);
	};

	onDeleteItem = (id) => {
		this.props.menusActions.deleteMenuItem(id);
	};

	onSortSections = (sections) => {
		this.props.menusActions.sortMenuSections(sections);
	};

	render() {

		const {restaurants, menuSections, menuItems, currentSections} = this.props.menus;
		const {loggedIn} = this.props.login;
		const {openRestaurant, data} = this.state;

		return (
			<div className="card">
				<div className="card-body">
					<h5 className="card-title text-primary"><Text>sections.menus</Text></h5>
				</div>
				<div className="card-footer bg-white">
					{(openRestaurant && data) ? <div>
							<Row centered={1}>
							<h2><Text name={data.name}>menus.selectedRestaurant</Text></h2>
								<Column autoCol={1}>
									<Select emptyOption={Xlate('menus.selectRestaurant')} noButtons={1} options={restaurants.map(rest => ({value : rest.id, label: rest.name }))} sort={(a,b) => a.label < b.label ? -1 : 1} exclude={[{value : openRestaurant}]} onSelect={this.onChangeRestaurant}/>
								</Column>
							</Row>
							<RestaurantMenuItems
								restaurantId={openRestaurant} menuItems={menuItems} menuSections={menuSections} currentSections={currentSections}
								onAddItem={this.onAddItem} onEditItem={this.onEditItem} onDeleteItem={this.onDeleteItem}
								onAddRestaurantSection={this.onAddRestaurantSection} />
						</div>
						:
					<div>
						{loggedIn && <Row>
						<Column className="col-md-6 pb-3">
							<RestaurantList list={restaurants} />
						</Column>
						<Column className="col-md-6 pb-3">
							<MenuSectionsList
										  onMergeSection={this.onMergeSection} onEditSection={this.onEditSection} onAddSection={this.onAddSection}
										  onAddRestaurantSection={this.onAddRestaurantSection}
										  onSortSections={this.onSortSections} list={menuSections}/>
						</Column>
					</Row>}
					</div>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {login: state.login, menus : state.menus }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		menusActions: bindActionCreators({...menusActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Menus);