import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as contactActions from '../actions/contactActions';
import Text from "../components/utils/Text";
import SupportListRow from "../components/support/SupportListRow";
import SupportListItem from "../components/support/SupportListItem";
import {Column, Row} from "../components/utils/Row";
import Icon from "../components/utils/Icon";


class Contact extends Component {

	constructor() {
		super();
		this.state = {
			openTicket : null,
			data : null,
		}
	}

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		this.setOpenTicket(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.match) this.setOpenTicket(nextProps);
	}

	onToggleShowSolved = () => {
		const {contactActions} = this.props;
		contactActions.toggleShowSolvedTickets();
	};

	onSelectTicket = (ticketId) => {
		const {contactActions} = this.props;
		const {tickets} = this.props.contact;
		let data = null;
		for (let ticket of tickets) { if (ticket.id*1 === ticketId*1) { data = ticket; break; }}

		if (data) {
			this.setState({openTicket : ticketId, data });

			if (!data.read) {
				contactActions.setContactTicketRead(ticketId);
			}

			this.props.history.push('/contact/'+ticketId);
		}
	};

	setOpenTicket(props) {
		const {tickets} = props.contact;

		if (props.match.params && props.match.params['id']) {
			const id = props.match.params['id']*1;
			let data = null;
			for (let ticket of tickets) { if (ticket.id*1 === id) { data = ticket; break; }}

			if (data) {
				this.setState({openTicket : id, data });
			}
		}
	}

	onReadToggle = (ticketId) => {
		const {tickets} = this.props.contact;
		const {contactActions} = this.props;

		const index = tickets.findIndex(ticket => ticket.id*1 === ticketId*1);
		if (index > -1) {
			const ticket = tickets[index];
			if (ticket.read) {
				contactActions.setContactTicketUnread(ticket.id);
			}
			else {
				contactActions.setContactTicketRead(ticket.id);
			}
		}
	};

	onSolvedToggle = (ticketId) => {
		const {tickets} = this.props.contact;
		const {contactActions} = this.props;

		const index = tickets.findIndex(ticket => ticket.id*1 === ticketId*1);
		if (index > -1) {
			const ticket = tickets[index];
			if (ticket.solved) {
				contactActions.setContactTicketUnsolved(ticket.id);
			}
			else {
				contactActions.setContactTicketSolved(ticket.id);
			}
		}
	};


	onAssign = (ticketId, adminId) => {
		const {contactActions} = this.props;
		contactActions.assignContactTicketToAdmin(ticketId, adminId);
	};


	render() {

		const {openTicket, data} = this.state;
		const {showSolved} = this.props.contact;
		const {adminsList} = this.props.admins;
		const tickets = (showSolved)? this.props.contact.tickets : this.props.contact.tickets.filter(ticket => !ticket.solved);
		const openContactTickets = (tickets) ? tickets.filter(ticket => !ticket.solved).length : 0;
		if (!tickets) return '';

		return (
			<div>
				<div className="card">
					<div className="card-body">
						<Row centered={1}>
							<Column className={'col-md'}><h5 className="card-title text-info"><Text>sections.contact</Text></h5></Column>
							<Column className={'col-auto-md'}>
								<input type="checkbox" className="mx-2" checked={showSolved} onChange={this.onToggleShowSolved} />
								<Text>contact.showSolvedTickets</Text>
							</Column>
						</Row>
					</div>
					<ul className="list-group list-group-flush">
						<li className="list-group-item list-group-item-light">{openContactTickets>0 && <Icon className="text-warning mx-2">exclamation-triangle</Icon>}
							<span className="font-weight-bold text-dark"> <Text num={openContactTickets}>contact.numUnsolvedMessages</Text></span>
						</li>
					</ul>
					<Row className="px-3">
						<Column className={'px-0 '+(openTicket?'col-md-4':'col-md-12')}>
							<div className="support-list list-group">
								{tickets.length === 0 && // No tickets
								<div className={"card"}>
									<div className={'card-body'}>
										<h5 className={"card-title text-success"}><Text>contact.noTickets</Text></h5>
										<p className={'card-text'}><Text>contact.noTicketsText</Text></p>
									</div>
								</div>
								}
								{tickets.map(item => <SupportListRow admins={adminsList} onSelectTicket={this.onSelectTicket} openTicket={openTicket} key={item.id} data={item}/>)}
							</div>
						</Column>
						{openTicket && <Column className="px-0 col-md-8">
							<SupportListItem onAssign={this.onAssign} admins={adminsList} onReadToggle={this.onReadToggle} onSolvedToggle={this.onSolvedToggle} data={data}/>
						</Column>}
					</Row>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {login: state.login, contact: state.contact, admins : state.admins }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		contactActions: bindActionCreators({...contactActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Contact);