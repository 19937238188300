import {Api} from "../resources/Api";

export const LOADING = 'LOADING_MISHLOHA';
export const MISHLOHA_ERROR = 'MISHLOHA_ERROR';
export const MISHLOHA_GET_REMOTE_SUCCESS = 'MISHLOHA_GET_REMOTE_SUCCESS';
export const MISHLOHA_GET_LOCAL_SUCCESS = 'MISHLOHA_GET_LOCAL_SUCCESS';
export const MISHLOHA_EDIT_LOCAL_SUCCESS = 'MISHLOHA_EDIT_LOCAL_SUCCESS';

export const getRemoteList = () => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.mishlohaGetRemote();
		if (res.list) {
			console.log(res);
			return dispatch({ type: MISHLOHA_GET_REMOTE_SUCCESS, list: res.list });
		}
		else return dispatch({ type: MISHLOHA_ERROR, error: res.error });
	}
};

export const getLocalList = () => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.mishlohaGetLocal();
		if (res.result === "success") {
			return dispatch({ type: MISHLOHA_GET_LOCAL_SUCCESS, list: res.list });
		}
		else return dispatch({ type: MISHLOHA_ERROR, error: res.error });
	}
};

export const editLocal = (mishlohaId, branchId) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.mishlohaEditLocal(mishlohaId, branchId);
		if (res.result === "success") {
			return dispatch({ type: MISHLOHA_EDIT_LOCAL_SUCCESS, mishlohaId, branchId });
		}
		else return dispatch({ type: MISHLOHA_ERROR, error: res.error });
	}
};