import React, {PureComponent} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Text, {Xlate} from "../utils/Text";
import {DateTimeFormat, UserStatus} from "../../resources/Filters";

export default class ReviewRow extends PureComponent {

	constructor() {
		super();
		this.state = {
			deleting : false,
		}
	}

	getBranch(branchId, restaurant) {
		if (!restaurant || !restaurant.branches) return '';
		for (let b of restaurant.branches) { if (b[0]*1 === branchId*1) return b[1]; }
		return '';
	}

	render() {

		const {review, restaurant, onApproveReview, onDeclineReview} = this.props;
		const {branch_id} = review;
		const branch = this.getBranch(branch_id, restaurant);
		return (
			<ul key={review.id} className="list-group list-group-flush">
				<li className="list-group-item">
					<Row centered={1}>
						<Column className={'col-md-2 col-lg-1 text-center'}>
							<div className={'btn disabled btn-lg font-weight-bold btn-block '+
							(review.rating>4 ?' btn-success':review.rating >3 ?' btn-info':review.rating > 2?' btn-warning':' btn-danger')}>{review.rating}</div>
						</Column>
						<Column className="col-md-3">
							<div className="font-weight-bold">{restaurant? restaurant.name : '-- '+Xlate('notFound')+' --'}</div>
							{branch}
							{(restaurant && restaurant.rating > 0) && <div className={"text-info"}>{parseFloat(restaurant.rating).toFixed(2)} <span className="text-secondary small">[<Text num={restaurant.reviews}>reviews.numReviews</Text>]</span></div>}
						</Column>
						<Column className="col-md">
							<div className="pb-2">{review.description}</div>
							<div className="card bg-light mx-0">
								<div className="card-body py-1">
									<Row centered={1} className="text-secondary">
										<Column className="col-lg small font-weight-bold">{DateTimeFormat(review.created)}</Column>
										<Column className="col-lg">{review.first_name} {review.last_name}</Column>
										<Column className="col-lg">{UserStatus(review.user_status)}</Column>
										<Column className="col-lg small">{review.email}</Column>
									</Row>
									<Row centered={1} className="text-secondary">
										<Column className="col-lg"><Text num={review.reviews}>reviews.wroteTotalReviews</Text></Column>
										<Column className="col-lg"><Text num={parseFloat(review.avg_rating).toFixed(2)}>reviews.avgRrating</Text></Column>
									</Row>
								</div>
							</div>
						</Column>
						<Column autoCol={1}>
							<Button primary={1} spaced={1} onClick={() => onApproveReview(review.id)}><Text>actions.confirm</Text></Button>
							<Button danger={1} onClick={() => onDeclineReview(review.id)}><Text>actions.decline</Text></Button>
						</Column>
					</Row>
				</li>
			</ul>
		);
	}
}