import {
	APPROVE_REVIEW,
	DECLINE_REVIEW,
	GET_REVIEWS_ERROR,
	GET_REVIEWS_SUCCESS,
} from '../actions/reviewsActions';

const defaultState = {
	pendingReviews : [],
	reviewsError : null,
};

export default function ReviewsReducer (state = defaultState, action) {
	switch (action.type) {

		case GET_REVIEWS_SUCCESS: {
			return {...state, pendingReviews: action.list }
		}

		case GET_REVIEWS_ERROR: {
			return {...state, reviewsError: action.error }
		}

		case DECLINE_REVIEW: {
			const index = state.pendingReviews.findIndex(s => s.id*1 === action.reviewId*1);
			const pendingReviews = [...state.pendingReviews.slice(0,index), ...state.pendingReviews.slice(index+1)];
			return {...state, pendingReviews};
		}

		case APPROVE_REVIEW : {
			const index = state.pendingReviews.findIndex(s => s.id*1 === action.reviewId*1);
			const pendingReviews = [...state.pendingReviews.slice(0,index), ...state.pendingReviews.slice(index+1)];
			return {...state, pendingReviews};
		}


		default:
			return state;
	}

};
