import {
	LOADING,
	LOGIN_LOGIN,
	LOGIN_LOGIN_SUCCESS,
	LOGIN_LOGIN_ERROR,
	LOGIN_RESTORE_HASH,
	LOGIN_RESTORE_HASH_SUCCESS,
	LOGIN_RESTORE_HASH_ERROR,
	LOGIN_LOGOUT
} from '../actions/loginActions';

const defaultState = {
	loading : false,
	loggedIn : false,
	loginError : null,
	rehash : false,
	superAdmin : false,
	credentials : {
		hash : null,
	}
};

export default function LoginReducer (state = defaultState, action) {
	switch (action.type) {

		case LOADING: {
			return {...state, loading : true }
		}

		case LOGIN_LOGIN: {
			return {...state, loading: true, loginError: null, }
		}

		case LOGIN_LOGIN_SUCCESS: {
			return {...state, loading: false, loggedIn: true, credentials : { ...state.credentials, hash: action.hash }, superAdmin: action.superAdmin }
		}

		case LOGIN_LOGIN_ERROR: {
			return {...state, loading: false, loginError: action.error }
		}

		case LOGIN_LOGOUT: {
			return {...state, loggedIn: false, credentials : defaultState.credentials }
		}

		case LOGIN_RESTORE_HASH : {
			return {...state, rehash: true, credentials: {...state.credentials, hash: action.hash }, superAdmin: action.superAdmin }
		}

		case LOGIN_RESTORE_HASH_SUCCESS : {
			return {...state, loading: false, rehash: false, loggedIn: true }
		}

		case LOGIN_RESTORE_HASH_ERROR: {
			return {...state, loading: false, rehash: false, loggedIn: false, credentials: {...state.credentials, hash: null }}
		}

		default:
			return state;

	}

};
