import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import * as loginActions from "../../actions/loginActions";
import {bindActionCreators} from "redux";
import Text from "../utils/Text";
import * as reviewsActions from "../../actions/reviewsActions";
import * as supportActions from "../../actions/supportActions";
import * as contactActions from "../../actions/contactActions";
import * as menusActions from "../../actions/menusActions";
import * as usersActions from "../../actions/usersActions";
import * as adminsActions from "../../actions/adminsActions";
import Loading from "./Loading";
import {TEST_ENV} from "../../resources/Api";

class Navbar extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading : false,
			initedUser : false,
		}
	}

	async initUser(superAdmin = false) {
		if (this.state.initedUser) return false;

		const {menusActions, reviewsActions, supportActions, contactActions, usersActions, adminsActions} = this.props;
		this.setState({initedUser : true});

		reviewsActions.getReviews();
		supportActions.getSupportTickets();
		contactActions.getContactTickets();
		menusActions.getRestaurants();
		menusActions.getMenuSections();
		usersActions.getUserCount();
		usersActions.getUserCountDaily();
		adminsActions.getAdminUsers();

	}

	doRehash() {
		const {loginActions} = this.props;

		loginActions.testHash();
	}


	componentDidMount() {
		if (this.props.login.loggedIn) {
			this.initUser(this.props.login.superAdmin);
		}
		else if (this.props.login.rehash) {
			this.doRehash();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.login.loggedIn) {
			this.initUser(this.props.login.superAdmin);
		}
	}

	render() {

		const {loggedIn, superAdmin} = this.props.login;
		const {pendingReviews} = this.props.reviews;
		const {mishloha, support, contact, login, menus, users} = this.props;
		const unreadSupportTickets = (support.tickets) ? support.tickets.filter(ticket => !ticket.read).length : 0;
		const unreadContactTickets = (contact.tickets) ? contact.tickets.filter(ticket => !ticket.read).length : 0;

		return (
			<div className={'navbar navbar-expand-lg navbar-light justify-content-between'} style={TEST_ENV?{backgroundColor: '#f8d7da'}:null}>
				<div className="container">
					<div className={"center-flex"}>
						<NavLink exact to="/"><img className="logo" alt="logo" src={process.env.PUBLIC_URL+"/logo.png"}/></NavLink>
						<ul className="navbar-nav">
							<li><NavLink exact to={'/'} className="nav-link" activeClassName="active">
								<Text>sections.home</Text></NavLink></li>
							{loggedIn && <li><NavLink to={'/reviews'} className="nav-link" activeClassName="active">
								<Text>sections.reviews</Text>{pendingReviews.length > 0 && <div className="badge badge-pill mx-1 badge-primary">{pendingReviews.length}</div>}
							</NavLink></li>}
							{loggedIn && <li><NavLink to={'/support'} className="nav-link" activeClassName="active">
								<Text>sections.support</Text>{unreadSupportTickets > 0 && <div className="badge badge-pill mx-1 badge-primary">{unreadSupportTickets}</div>}
							</NavLink></li>}
							{loggedIn && <li><NavLink to={'/contact'} className="nav-link" activeClassName="active">
								<Text>sections.contact</Text>{unreadContactTickets > 0 && <div className="badge badge-pill mx-1 badge-primary">{unreadContactTickets}</div>}
							</NavLink></li>}
							{loggedIn && <li><NavLink to={'/menus'} className="nav-link" activeClassName="active">
								<Text>sections.menus</Text>
							</NavLink></li>}
							{loggedIn && <li><NavLink to={'/users'} className="nav-link" activeClassName="active">
								<Text>sections.users</Text>
							</NavLink></li>}
							{(loggedIn && superAdmin) && <li><NavLink to={'/admins'} className="nav-link" activeClassName="active">
								<Text>sections.admins</Text>
							</NavLink></li>}
							{loggedIn && <li><a href="/test.tar" download className="nav-link">
								<Text>sections.testDownloadSpeed</Text>
							</a></li>}
						</ul>
					</div>
					<div className="navbar-text text-center">
						{loggedIn?<Text>login.loggedIn</Text>:<Text>login.signedOut</Text>}<br />
						{loggedIn && <NavLink to={'/logout'}>[<Text>login.logOut</Text>]</NavLink>}
					</div>
				</div>
				{(login.loading || menus.loading || users.loading || mishloha.loading ) && <Loading />}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {login: state.login, reviews: state.reviews, support: state.support, contact: state.contact, menus: state.menus, users: state.users, mishloha: state.mishloha }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		menusActions: bindActionCreators({...menusActions}, dispatch),
		reviewsActions: bindActionCreators({...reviewsActions}, dispatch),
		supportActions: bindActionCreators({...supportActions}, dispatch),
		contactActions: bindActionCreators({...contactActions}, dispatch),
		usersActions: bindActionCreators({...usersActions}, dispatch),
		adminsActions: bindActionCreators({...adminsActions}, dispatch),
	}

};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);