import React, {PureComponent} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import {Xlate} from "../utils/Text";
import Icon from "../utils/Icon";

export default class AdminAddRow extends PureComponent {

	constructor() {
		super();
		this.state = {
			editing : false,
			deleting : false,
			username : '',
			password : '',
		}
	}

	onChange = (option, value) => {
		this.setState({[option] : value});
	};

	render() {

		const {admin} = this.props;
		const {username, password} = this.state;
		console.log(admin);

		return (
			<div>
				<form onSubmit={this.onAddItem}>
					<Row centered={1}>
						<input type="text" placeholder={Xlate('fields.username')} className="form-control" value={username} onChange={(e) => this.onChange('username', e.target.value)} />
						<input type="password" placeholder={Xlate('fields.password')} className="form-control" value={password} onChange={(e) => this.onChange('password', e.target.value)} />
						<Column className="col-auto font-weight-bold">
							<Button type="submit" success={1} spaced={1} onClick={this.onAddAdmin}><Icon>check</Icon></Button>
							<Button light={1} onClick={(this.props.onDismiss?this.props.onDismiss:null)}><Icon>times</Icon></Button>
						</Column>
					</Row>
				</form>
			</div>
		);
	}
}