import React, {Component} from 'react';
import {DateTimeFormat, Nl2br, UserStatus} from "../../resources/Filters";
import Text, {Xlate} from "../utils/Text";
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";

export default class SupportListItem extends Component {

	onChangeAssigned = (e) => {
		const adminId = e.target.value;
		const {data} = this.props;

		this.props.onAssign(data.id, adminId)
	};

	render() {

		const {data} = this.props;
		if (!data) return '';

		const {content, subject, created, email, status, read, solved, assigned_to, first_name, last_name, solved_date, typed_email} = this.props.data;
		const {onReadToggle, onSolvedToggle, admins} = this.props;


		return (
			<div className="card">
				<div className="card-header font-weight-bold">
					<Row>
						<Column className="col-md-auto">
							{first_name + ' '+last_name} &lt;{email?email.trim():''}&gt;
							{typed_email && (!email || typed_email.toLowerCase().trim() !== email.toLowerCase().trim())?'('+typed_email+')':''}
						</Column>
						<Column className="text-primary">
							<strong>{UserStatus(status)}</strong>
						</Column>
						<Column className="col-md-auto">
							{DateTimeFormat(created)}
						</Column>
						{(solved && solved_date) ? <Column className="col-auto text-success">
							<Text>support.solvedOn</Text> {DateTimeFormat(solved_date)}
						</Column>:''}
						<Column className="col-md-auto">
							<Button primary={1} small={1} spaced={1} onClick={() => onSolvedToggle(data.id)}>
								<i className={'fas ' + (solved?'fa-times':'fa-check')}></i>
							</Button>
							{!solved && <Button info={1} small={1} onClick={() => onReadToggle(data.id)}>
								<i className={'fas ' + (read?'fa-eye-slash':'fa-eye')}></i>
							</Button>}
						</Column>
					</Row>
				</div>
				<div className="card-body">
					{(subject && subject !== "Bug Report") && <h4>{subject}</h4>}
				<p>{Nl2br(content)}</p>
				</div>
				<div className="card-footer">
					<Text>support.assignedTo</Text>
					<select value={(assigned_to>0?assigned_to+'':'')} onChange={this.onChangeAssigned}>
						<option>{Xlate('support.chooseAdmin')}</option>
						{admins && admins.map((admin, index) => <option key={index} value={admin.id}>{admin.full_name}</option>)}
					</select>
				</div>
			</div>
		)
	}
}