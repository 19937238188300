import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import ReviewRow from "../components/Reviews/ReviewRow";

class Reviews extends Component {

	onApproveReview = (id) => {
		const {approveReview} = this.props.reviewsActions;
		approveReview(id);
	};

	onDeclineReview = (id) => {
		const {declineReview} = this.props.reviewsActions;
		declineReview(id);
	};

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);
	}

	render() {

		const {reviews, login} = this.props;
		const {restaurants} = this.props.menus;
		const pendingReviews = reviews.pendingReviews.sort((a,b) => (a.id*1<b.id*1)?1:-1);

		return (
			<div>
				{login.loggedIn &&
				<div className="card-deck mt-3">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title text-secondary"><Text>sections.reviews</Text></h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item list-group-item-light">{reviews.pendingReviews.length>0 && <Icon className="text-warning mx-2">exclamation-triangle</Icon>}
								<span className="font-weight-bold text-dark"> <Text num={pendingReviews.length}>reviews.numPendingReviews</Text></span>
							</li>
						</ul>
						{pendingReviews.map(review => {
							const restaurant = restaurants.find(rest => rest.id === review.restaurant_id);
							return (
								<ReviewRow key={review.id} review={review} restaurant={restaurant}
										   onApproveReview={() => this.onApproveReview(review.id)}
										   onDeclineReview={() => this.onDeclineReview(review.id)} />
							)
						}
						)}
					</div>

				</div>}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, menus: state.menus, reviews: state.reviews }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		reviewsActions: bindActionCreators({...reviewsActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Reviews);