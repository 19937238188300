import React, {Component} from 'react';
import RestaurantMenuItemRow from "./RestaurantMenuItemRow";
import Icon from "../utils/Icon";
import Button from "../utils/Button";
import {Xlate} from "../utils/Text";
import Text from "../utils/Text";
import RestaurantMenuItemAdd from "./RestaurantMenuItemAdd";
import Select from "../utils/Select";

export default class RestaurantMenuItems extends Component {

	constructor() {
		super();
		this.state = {
			sectionIndex : 0,
			sections : [],
			addingSection : false,
			addingItem : false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.currentSections && nextProps.menuSections) {
			let sections = [];
			let section;
			for (let s of nextProps.currentSections) {
				section = nextProps.menuSections.find(sect => sect.id*1 === s*1);
				if (section) sections.push(section);
			}

			sections = sections.sort((a,b) => a.position - b.position);

			this.setState({sections});
		}
	}

	addSection = (sectionId) => {
		const section = this.props.menuSections.find(sect => sect.id*1 === sectionId*1);
		if (section) {
			let sections = this.state.sections;
			sections.push(section);
			sections = sections.sort((a,b) => a.position - b.position);
			this.setState({sections});

			this.props.onAddRestaurantSection(sectionId);
			this.toggleAddingSection();
		}
	};

	onAddItem = (sectionId, title, description, price) => {
		this.props.onAddItem(this.props.restaurantId, sectionId, title, description, price);
	};

	toggleAddingSection = () => {
		this.setState({addingSection : !this.state.addingSection})
	};

	toggleAddingItem = () => {
		this.setState({addingItem : !this.state.addingItem})
	};

	render() {

		let {menuItems} = this.props;
		const {sections, sectionIndex, addingSection, addingItem} = this.state;

		menuItems = menuItems.sort((a,b) => a.votes*1 < b.votes*1);

		return (
			<div>
				<ul className="nav nav-tabs">
					{sections && sections.map((section, index) =>
						<li key={index} className="nav-item">
							<div className={'nav-link' + (sectionIndex===index ? ' active':' text-primary')} onClick={() => this.setState({sectionIndex : index})}>{section.title}</div>
						</li>
					)}
					<li key={'add-section-tab'} className="nav-item">
						<div className="nav-link">
							{addingSection ?
								<Select exclude={sections.map(section => ({value : section.id, label: section.title}))} options={this.props.menuSections.map(section => ({value : section.id, label: section.title}))} emptyOption={Xlate('menus.selectSection')} onSelect={this.addSection} cancel={this.toggleAddingSection} />:
								<Button primary={1} xs={1} onClick={this.toggleAddingSection}><Icon>plus</Icon></Button>}
						</div>
					</li>
				</ul>
				<div className={'card'}>
					<ul className="list-group list-group-flush">
						{sections && <li key={'add-menu-item-row'} className="list-group-item list-group-item-light">
							{addingItem ?
							<RestaurantMenuItemAdd onAddItem={(a,b,c) => this.onAddItem(sections[sectionIndex].id, a,b,c)} onDismiss={this.toggleAddingItem}/>:
								<div>
									{sections[sectionIndex] && <Button primary={1} small={1} onClick={this.toggleAddingItem}><Text>menus.addItem</Text></Button>}
								</div>
							}
						</li>}
					{(sections && sections[sectionIndex]) && menuItems.map((item, index) =>
					item.section_id === sections[sectionIndex].id &&
						<li key={index} className="list-group-item">
							<RestaurantMenuItemRow item={item}
							   onEditItem={(a,b,c) => this.props.onEditItem(item.id, a,b,c)}
							   onDeleteItem={() => this.props.onDeleteItem(item.id)}
							/>
						</li>
					)}
					</ul>
				</div>
			</div>
		);
	}
}