import axios from 'axios';

export const TEST_ENV = true;
export const LOCAL_ENV = false;

//	in order to pass the "login" on a local instance?
//	getting network error, even after setting different ports.
const API_URI = (LOCAL_ENV ? 'http://localhost:8017/admin/' : (TEST_ENV) ? 'https://app2.vegan-friendly.co.il/admin/' : 'https://app.vegan-friendly.co.il/admin/');
const API_HASH_PREFIX = '?hash=';
const API_DEBUG = false;


export class Api {

	static _credentials = {
		login : null,
		password: null,
		hash : null,
	};

	///////////////////////////////////////////////////////////////////////////
	// Support
	///////////////////////////////////////////////////////////////////////////

	static async supportTicketsList() {
		const res = await Api._doGet('support/list');
		return { result: 'success', list : res.list }
	}

	static async setSupportTicketRead(ticketId) {
		return await Api._doGet('support/read/'+ ticketId );
	}

	static async setSupportTicketUnread(ticketId) {
		return await Api._doGet('support/unread/'+ ticketId );
	}

	static async setSupportTicketSolved(ticketId) {
		return await Api._doGet('support/solved/'+ ticketId );
	}

	static async setSupportTicketUnsolved(ticketId) {
		return await Api._doGet('support/unsolved/'+ ticketId );
	}

	static async assignSupportTicketToAdmin(ticketId, adminId) {
		return await Api._doGet('support/assign/'+ ticketId+'/'+adminId );
	}

	///////////////////////////////////////////////////////////////////////////
	// Contact
	///////////////////////////////////////////////////////////////////////////

	static async contactTicketsList() {
		const res = await Api._doGet('contact/list');
		return { result: 'success', list : res.list }
	}

	static async setContactTicketRead(ticketId) {
		return await Api._doGet('contact/read/'+ ticketId );
	}

	static async setContactTicketUnread(ticketId) {
		return await Api._doGet('contact/unread/'+ ticketId );
	}

	static async setContactTicketSolved(ticketId) {
		return await Api._doGet('contact/solved/'+ ticketId );
	}

	static async setContactTicketUnsolved(ticketId) {
		return await Api._doGet('contact/unsolved/'+ ticketId );
	}

	static async assignContactTicketToAdmin(ticketId, adminId) {
		return await Api._doGet('contact/assign/'+ ticketId+'/'+adminId );
	}

	///////////////////////////////////////////////////////////////////////////
	// Reviews
	///////////////////////////////////////////////////////////////////////////
	static async reviewsList() {
		const res = await Api._doGet('reviews/pending');
		return { result: 'success', list : res }

	}

	static async approveReview(reviewId) {
		return await Api._doGet('reviews/approve/'+ reviewId );
	}

	static async declineReview(reviewId) {
		return await Api._doGet('reviews/remove/'+ reviewId );
	}

	///////////////////////////////////////////////////////////////////////////
	// Restaurants
	///////////////////////////////////////////////////////////////////////////

	static async restaurantsList() {
		const res = await Api._doGet('restaurants/list');
		return { result: 'success', list : res }
	}

	static async reloadRestaurants() {
		const res = await Api._doGet('reload/the/restaurants');
		return res;
	}

	///////////////////////////////////////////////////////////////////////////
	// Menu Sections
	///////////////////////////////////////////////////////////////////////////

	static async menuSectionsList() {
		const res = await Api._doGet('menu_sections/list');
		return { result: 'success', list : res }
	}

	static async menuSectionsMerge(oldSection, newSection) {
		return await Api._doGet('menu_sections/merge/'+oldSection+'/'+ newSection );
	}

	static async menuSectionsSort(sections) {
		return await Api._doPost('menu_sections/sort', {sections});
	}

	static async menuSectionsEdit(id, title) {
		return await Api._doPost('menu_sections/edit', {id, title});
	}

	static async menuSectionsAdd(title, position) {
		return await Api._doPost('menu_sections/add', {title, position});
	}

	///////////////////////////////////////////////////////////////////////////
	// Menu Items
	///////////////////////////////////////////////////////////////////////////

	static async menuItemsList(restaurantId) {
		return await Api._doGet('menu_items/list/'+ restaurantId);
	}

	static async menuItemAdd(restaurant_id, section_id, title, description, price) {
		return await Api._doPost('menu_items/add',  {restaurant_id, section_id, title, description, price});
	}

	static async menuItemEdit(id, title, description, price) {
		return await Api._doPost('menu_items/edit/' + id,  {title, description, price});
	}

	static async menuItemDelete(id) {
		return await Api._doGet('menu_items/delete/' + id);
	}

	///////////////////////////////////////////////////////////////////////////
	// Users
	///////////////////////////////////////////////////////////////////////////

	static async usersGetList() {
		return await Api._doGet('users');
	}

	static async usersGetCount() {
		return await Api._doGet('user-count');
	}

	static async usersGetCountDaily() {
		return await Api._doGet('user-count-daily');
	}

	static async usersSignOutSubscriber(subscriberId) {
		return await Api._doGet('user/subscriber-signout/'+subscriberId);
	}

	static async usersDeleteUser(userId) {
		return await Api._doGet('user/remove/'+userId);
	}

	static async adminsGetList() {
		return await Api._doGet('admins-list');
	}

	///////////////////////////////////////////////////////////////////////////
	// Admins (for superadmin)
	///////////////////////////////////////////////////////////////////////////

	static async adminsCreateAdmin(username, password) {
		return await Api._doPost('admins/add', {username, password});
	}

	static async adminsEditAdmin(adminId, username, password) {
		return await Api._doPost('admins/edit/'+adminId, {username, password});
	}

	static async adminsDeleteAdmin(adminId) {
		return await Api._doGet('admins/remove/'+adminId);
	}

	///////////////////////////////////////////////////////////////////////////
	// Mishloha
	///////////////////////////////////////////////////////////////////////////
	static async mishlohaGetRemote() {
		return await Api._doGet('mishloha/get-remote');
	}

	static async mishlohaGetLocal() {
		return await Api._doGet('mishloha/get-local');
	}

	static async mishlohaEditLocal(mishlohaId, branchId) {
		return await Api._doPost('mishloha/edit', {mishloha_id: mishlohaId, branch_id : branchId});
	}

	///////////////////////////////////////////////////////////////////////////
	// Login
	///////////////////////////////////////////////////////////////////////////

	static async testHash() {
		return await Api._doGet('testhash');
	}

	static async generateHash(string) {
		return await Api._doPost('get-salted-hash', {string});
	}

	static async login(login, password) {
		const res = await Api._doPost('login', {login, password}, true);
		if (res.result === 'success') {
			Api._credentials = {
				login, password, hash: res.hash
			};
			return {result: 'success', hash: res.hash, is_super : res.is_super };
		}
		else {
			return {result: 'error', error: res.error }
		}
	}

	static async logout() {
		return await Api._doGet('logout');
	}


	///////////////////////////////////////////////////////////////////////////
	// -- UTILS --
	///////////////////////////////////////////////////////////////////////////

	static async _doPost(path, data = {}, noCredentials = false) {
		if (API_DEBUG) console.log("API POST:",path,data);
		if (!Api._credentials.hash && !noCredentials) return Api._noCredentials();

		path += API_HASH_PREFIX + Api._credentials.hash;

		try {
			const response = await axios.post(API_URI + path, data);
			return response.data;
		}
		catch (err) {
			return Api._requestError(err);
		}
	}

	static async _doGet(path, data = {}, noCredentials = false) {
		if (API_DEBUG) console.log("API GET:",path,data);
		if (!Api._credentials.hash && !noCredentials) return Api._noCredentials();

		path += API_HASH_PREFIX + Api._credentials.hash;

		try {
			const response = await axios.get(API_URI + path, data);
			return response.data;
		}
		catch (err) {
			return Api._requestError(err);
		}
	}

	static _noCredentials() {
		return {result: 'error', errorType: "noHash", error: "No admin hash supplied"};
	}

	static _requestError(error) {
		return { result: 'error', error:(error.message)?error.message:error };
	}

}