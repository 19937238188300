import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as mishlohaActions from '../actions/mishlohaActions';
import Text, {Xlate} from "../components/utils/Text";
import {Row, Column} from "../components/utils/Row";
import Icon from "../components/utils/Icon";
import Button from "../components/utils/Button";

class Mishloha extends Component {

	constructor(props) {
		super(props);
		this.state = {
			editing: -1,
			list : null
		}
	}

	componentDidMount() {

		this.setState({list : null});

		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 1500);

		const { remoteList, localList } = this.props.mishloha;

		if (!remoteList || !localList) {
			this.loadLists();
		}
		else this.matchLists();

	}

	async loadLists() {
		const { getRemoteList, getLocalList } = this.props.mishlohaActions;
		await getRemoteList();
		await getLocalList();
		this.matchLists();
	}

	onEditRow(mishlohaId) {
		this.setState({editing: mishlohaId});
		console.log("Editing", mishlohaId);
	}

	async onChangeBranch(mishlohaId, branchId) {
		const { editLocal } = this.props.mishlohaActions;
		await editLocal(mishlohaId, branchId);
		this.setState({editing: -1});
		this.matchLists();
	}

	matchLists() {
		const { remoteList, localList } = this.props.mishloha;
		if (!remoteList || !localList) return null;

		const list  = [];
		let item = null, match;
		for (let r = 0; r < remoteList.length; r++) {
			match = '';
			item = remoteList[r];
			localList.map(local => {
				if (item.id*1 === local.mishloha_id*1) {
					match = local.branch_id;
					console.log(local, item);
				}
			});
			list.push({...item, match })
		}

		this.setState({list});
	}

	getBranchesDropdownOptions() {
		const restaurantsBranches = this.props.menus.restaurants;

		let list = [];
		let id;
		for (let rest of restaurantsBranches) {
			if (rest.branches && rest.branches.length > 0)
				for (let branch of rest.branches) {
					id = branch[0].replace(/,/g,'');
					list.push({'id': id, 'name' : rest.name, 'address': branch[1]})
				}
		}

		list = list.sort((a,b) => a.name > b.name?1:-1);

		return list;

	}

	render() {

		const { list, editing } = this.state;

		let dropdownOptions;

		let currentRest;
		if (editing > -1) {
			dropdownOptions = this.getBranchesDropdownOptions();
		}

		return (
			<div>
				<h2><Text>admin.mishloha</Text></h2>

				<div className={"card"}>
					<ul className={'list-group list-group-flush'}>
						{list && list.map(li =>

						editing === li.id ?

							<li key={li.id} className={'list-group-item'}>
							<Row>
								<Column>
									<div><strong>{li.name}</strong></div>
								</Column>
								<Column>
									<div>
										<select value={li.match} onChange={(e,v) => this.onChangeBranch(li.id, e.target.value)}>
											<option value={''} key={'noopt'}>- {Xlate('actions.select')} -</option>
											{dropdownOptions.map(opt =>
												<option key={opt.id} value={opt.id}>{opt.name}: {opt.address}</option>
											)}
										</select>
									</div>
								</Column>
							</Row>
							</li>

						:
							<li key={li.id} className={'list-group-item'}>
								<Row>
									<Column className={'col-2'}>
										<Button primary={!!li.match} danger={!li.match} onClick={e => this.onEditRow(li.id)}>
											<Icon>{li.match?'edit':"plus"}</Icon>
										</Button>
									</Column>
									<Column>
										<div>{li.name}</div>
									</Column>
								</Row>
							</li>
						)}
					</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, mishloha: state.mishloha, menus: state.menus }
};

const mapDispatchToProps = dispatch => {
	return {
		mishlohaActions: bindActionCreators({...mishlohaActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Mishloha);