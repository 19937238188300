import React from 'react';
import ChartistGraph from 'react-chartist';
import ctPointLabels from 'chartist-plugin-pointlabels';

export default class UsersBar extends React.Component {

	render() {

		const labels = [], seriesAll = [], seriesRegular = [], seriesSubscribers = [];

		for (let d of this.props.data) {
			labels.push(d.hr);
			seriesAll.push((d.total*1>0)?d.total*1:0);
			seriesRegular.push((d.regular*1>0)?d.regular*1:0);
			seriesSubscribers.push((d.subscribers*1>0)?d.subscribers*1:0);
		}

		const data = {
			labels,
			// Our series array that contains series objects or in this case series data arrays
			series: [ seriesAll, seriesRegular, seriesSubscribers ]
		};

		const options = {
			axisX: {
			},
			axisY : {
				labelInterpolationFnc: function(value) {
					return parseInt(value);
				},
			},
			plugins:[
				ctPointLabels({
					textAnchor: 'middle',
					labelInterpolationFnc: function(value) {
						if (!value) value = 0;
						return parseInt(value);
					},
				})
			]
		};

		const type = 'Line';

		return (
			<ChartistGraph className="ct-minor-seventh" data={data} options={options} type={type} />
		)
	}
}