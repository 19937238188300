import React, {Component} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import Confirmation from "../utils/Confirmation";

export default class RestaurantMenuItemRow extends Component {

	constructor() {
		super();
		this.state = {
			editing : false,
			deleting : false,
			title : '',
			description: '',
			price : '',
		}
	}

	componentDidMount() {
		const {item} = this.props;
		this.setState({
			title : item.title,
			description : item.description,
			price : item.price,
		})
	}

	onChange = (option, value) => {
		this.setState({[option] : value});
	};

	toggleEdit = () => {
		this.setState({editing : !this.state.editing });
	};

	toggleDelete = () => {
		this.setState({deleting : !this.state.deleting });
	};

	onEditItem = () => {
		this.props.onEditItem(this.state.title, this.state.description, this.state.price);
		this.toggleEdit();
	};

	onDeleteItem = () => {
		this.props.onDeleteItem();
	};

	render() {

		const {item} = this.props;
		const {editing, deleting} = this.state;

		return (
			<div>
			{editing ?
				<Row centered={1}>
					<input type="text" className="form-control" value={this.state.title} onChange={(e) => this.onChange('title', e.target.value)} />
					<input type="text" className="form-control" value={this.state.description} onChange={(e) => this.onChange('description', e.target.value)} />
					<input type="text" className="form-control" value={this.state.price} onChange={(e) => this.onChange('price', e.target.value)} />
					<Column className="col-auto font-weight-bold">
						<Button success={1} spaced={1} onClick={this.onEditItem}><Icon>check</Icon></Button>
						<Button light={1} onClick={this.toggleEdit}><Icon>times</Icon></Button>
					</Column>
				</Row>
				:
			<Row centered={1}>
				<Column autoCol={1}>
					<span className="fa-stack text-center">
					  <i className={'fas fa-heart fa-stack-2x '+(item.votes*1>0?'text-danger':'text-light')} />
						{item.votes*1>0?<span className="fa-stack-1x text-white font-bold">{item.votes}</span>:''}
					</span>
				</Column>
				<Column>
					<div className="font-weight-bold">{item.title}</div>
					<div>{item.description}</div>
				</Column>
				<Column className="col-auto font-weight-bold">
					{item.price}
				</Column>
				<Column autoCol={1}>
					{deleting?
						<Confirmation onDismiss={this.toggleDelete} onConfirm={this.onDeleteItem} />
						:
						<div>
							<Button primary={1} spaced={1} onClick={this.toggleEdit}><Icon>edit</Icon></Button>
							<Button danger={1} spaced={1} onClick={this.toggleDelete}><Icon>trash</Icon></Button>
						</div>
					}

				</Column>
			</Row>
			}
			</div>
		);
	}
}