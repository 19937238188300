import React, {PureComponent} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";

export default class AdminRow extends PureComponent {

	constructor() {
		super();
		this.state = {
			editing : false,
			deleting : false,
		}
	}

	render() {

		const {admin} = this.props;

		return (
			<ul className="list-group list-group-flush">
				<li className="list-group-item">
					<Row centered={1}>
						<Column autoCol={1}>
							<Icon className={"mr-1 "+(admin.superAdmin?"text-warning":"text-light")}>star</Icon>
							<Icon className={(admin.active?"text-success":"text-secondary")}>{admin.active?'check':'times'}</Icon>
						</Column>
						<div className="font-weight-bold">
							{admin.username}
						</div>
						{!admin.superAdmin &&
						<Column autoCol={1}>
							<Button className="mr-1" danger={1}><Icon>times</Icon></Button>
							<Button primary={1} className="mr-1"><Icon>edit</Icon></Button>
							<Button info={1} outline={1}><Icon>history</Icon></Button>
						</Column>}
					</Row>
				</li>
			</ul>
		);
	}
}