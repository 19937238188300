import {
	LOADING, MISHLOHA_EDIT_LOCAL_SUCCESS,
	MISHLOHA_ERROR,
	MISHLOHA_GET_LOCAL_SUCCESS,
	MISHLOHA_GET_REMOTE_SUCCESS
} from '../actions/mishlohaActions';

const defaultState = {
	remoteList : null,
	localList : null,
	error : null,
	loading: false,
};

export default function MishlohaReducer (state = defaultState, action) {

	switch (action.type) {

		case LOADING : {
			return {...state, loading: true }
		}

		case MISHLOHA_ERROR : {
			return {...state, loading: false, error: action.error }
		}

		case MISHLOHA_GET_LOCAL_SUCCESS : {
			return { ...state, localList : action.list, loading: false }
		}

		case MISHLOHA_EDIT_LOCAL_SUCCESS: {
			const index = state.localList.findIndex(u => u.mishloha_id*1 === action.mishlohaId*1);
			let localList = state.localList;
			if (index > -1) {
				if (action.branchId) {
					localList = [...state.localList.slice(0,index), {...state.localList[index], match : action.branchId }, ...state.localList.slice(index+1)];
				}
				else {
					localList = [...state.localList.slice(0,index), ...state.localList.slice(index+1)];
				}
			}
			else localList = [...state.localList, {branch_id: action.branchId, mishloha_id : action.mishlohaId}];
			return {...state, localList, loading: false }
		}

		case MISHLOHA_GET_REMOTE_SUCCESS : {
			return {...state, remoteList: action.list, loading: false }
		}


		default:
			return state;
	}

};
