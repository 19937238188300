import React, {Component} from 'react';
import Text, {Xlate} from "../utils/Text";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import Select from "../utils/Select";
import LineInput from "../utils/LineInput";

const MAX_ITEMS_TO_SHOW = 1000;

export default class MenuSectionsList extends Component {

	searchDebounceTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			search : '',
			editing: null,
			merging : null,
			adding : false,
			itemsToShow : MAX_ITEMS_TO_SHOW,
		}
	}

	filteredList() {
		const {search, itemsToShow} = this.state;
		const list = (search && search.length > 0) ? this.props.list.filter((item) => item.title.toLowerCase().indexOf(search.toLowerCase()) > -1) : this.props.list;
		return list.slice(0, itemsToShow);
	}

	sortedList(list) {
		return list.sort((a,b) => a.position*1-b.position*1)
	}

	onSearchChange = (search) => {
		this.setState({search});

		clearTimeout(this.searchDebounceTimeout);
		this.searchDebounceTimeout = setTimeout(() => this.searchMenuSections(), 300);
	};

	searchMenuSections() {
	}

	onEditButton = (id) => {
		if (id === this.state.editing)
			this.setState({editing: null, merging: null});
		else
			this.setState({editing: id, merging: null});
	};

	onMergeButton = (id) => {
		if (id === this.state.merging)
			this.setState({merging: null, editing: null});
		else
			this.setState({merging: id, editing: null});
	};

	onAddButton = () => this.setState({adding: true});

	onMergeSection = (newSection, oldSection) => {
		this.props.onMergeSection(oldSection, newSection);
		this.setState({merging: null, editing: null});
	};

	onEditSection = (id, title) => {
		this.props.onEditSection(id, title);
		this.setState({merging: null, editing: null});
	};

	onAddSection = (title) => {
		if (title) {
			const maxPosition = Object.keys(this.props.list).reduce((acc, val) => (this.props.list[val].position*1 > acc*1)?this.props.list[val].position*1:acc);
			this.props.onAddSection(title, maxPosition+1);
			this.onAddDismiss();
		}
	};

	onAddDismiss = () => {
		this.setState({adding: false});
	};

	onDragEnd = (result) => {

		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const list = this.sortedList(this.filteredList());


		const items = this.reorder(
			list,
			result.source.index,
			result.destination.index
		);

		const data = [];
		for (let item of items) {
			data.push({id : item.id, position: item.position })
		}

		this.props.onSortSections(data);
	};

	// a little function to help us with reordering the result
	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		let pos = [];
		for (let i = 0; i < result.length; i++) {
			pos.push(result[i].position);
			// result[i].position = i;
		}
		pos = pos.sort((a, b) => a*1-b*1);

		for (let i = 0; i < result.length; i++) {
			result[i].position = pos[i];
		}

		return result;
	};

	render() {

		const list = this.sortedList(this.filteredList());
		const {adding, editing, merging} = this.state;

		return (
			<div className={'card border-secondary'}>
				<div className="card-header">
					<Row>
						<h4><Text>menus.menuSections</Text></h4>
						<Column autoCol={1}>
							{adding?
							<LineInput onDismiss={this.onAddDismiss} onSubmit={this.onAddSection} />
							:
							<Button success={1} onClick={this.onAddButton}>
								<Icon>plus</Icon>
							</Button>
							}
						</Column>
					</Row>
				</div>
				<div className="card-header">
					<LineInput noButtons={1} icon="search" value={this.state.search} onSubmit={this.onSearchChange} />
				</div>

				<DragDropContext onDragEnd={this.onDragEnd}>
					<div className="list-group list-group-flush" style={{height: 'calc(100vh - 350px)', overflowY: 'auto'}}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}>
								{list.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="list-group-item">
												<Row>
													<Column className="ibs">
														{item.id === editing ? // EDITING
															<div>
																<LineInput buttonLabel={<Text>menus.update</Text>} value={item.title} onSubmit={(title) => this.onEditSection(item.id, title)} onDismiss={() => this.onEditButton(item.id)}/>
															</div>:
															item.id === merging ? // MERGING
																<div>
																	<Select onSelect={this.onMergeSection}
																			emptyOption={Xlate('menus.selectMergeSection')}
																			buttonLabel={Xlate('menus.merge')} refId={item.id}
																			options={this.props.list
																				.filter(section => section.id !== item.id)
																				.map(section => ({ value:section.id, label: section.title }))}/>
																</div>: // VIEW ONLY
																<div>
																	<span>{item.title}</span>
																	<span>[{item.num}]</span>
																</div>
														}
													</Column>
													<Column autoCol={1}>
														<Button primary={1} spaced={1} onClick={() => this.onEditButton(item.id)}><Icon>edit</Icon></Button>
														<Button danger={1} onClick={() => this.onMergeButton(item.id)}><Icon>random</Icon></Button>
													</Column>
												</Row>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					</div>
				</DragDropContext>
			</div>
		);
	}
}