import {Api} from "../resources/Api";

export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_ERROR = 'GET_REVIEWS_ERROR';
export const APPROVE_REVIEW = 'APPROVE_REVIEW';
export const DECLINE_REVIEW = 'DECLINE_REVIEW';

export const getReviews = () => {
	return async (dispatch) => {


		const res = await Api.reviewsList();

		if (res.result === "success") {
			return dispatch({
				type: GET_REVIEWS_SUCCESS,
				list: res.list
			})
		}
		else {
			return dispatch({
				type: GET_REVIEWS_ERROR,
				error: "empty"
			});
		}
	}
};

export const approveReview = (reviewId) => {
	return async (dispatch) => {

		const res = await Api.approveReview(reviewId);
		if (res.result === "success") {
			return dispatch({
				type: APPROVE_REVIEW,
				reviewId
			})
		}

	}
};

export const declineReview = (reviewId) => {
	return async (dispatch) => {

		const res = await Api.declineReview(reviewId);
		if (res.result === "success") {
			return dispatch({
				type: DECLINE_REVIEW,
				reviewId
			})
		}

	}
};